import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse, Link, Button, Grid } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import SmsIcon from '@mui/icons-material/Sms';

const UnderstandingCredits = () => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleDownloadVCF = () => {
        const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:MarsReel Academy
TEL;TYPE=WORK,VOICE:+18773556524
END:VCARD
        `;
        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'MarsReelAcademy.vcf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSendSMS = () => {
        const phoneNumber = '+18773556524';
        const message = encodeURIComponent('I would like to purchase credits.');
        window.open(`sms:${phoneNumber}?body=${message}`, '_blank');
    };

    return (
        <Box
            sx={{
                backgroundColor: '#202020',
                padding: '1.5rem',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                textAlign: 'left',
                color: '#fff',
                marginBottom: '1rem'
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Getting Started
                </Typography>
                <IconButton onClick={handleToggle}>
                    {open ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography variant="body1" sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
                    What are credits?
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                    Credits are needed in the Mars Reel Academy app to access special activities like training clinics, scrimmages, and fireside chats that help athletes improve their skills.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
                    Purchasing Credits
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                    • Recurring Plan: Offers 8 credits for $250 per month, making each credit $31.25—a 22% savings compared to the pay-as-you-go rate. Credits reload monthly, and additional credits can be purchased at the standard rate of $40 each.
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                    • Pay-as-You-Go: Credits can be purchased individually as needed at $40 per credit, offering flexibility without any subscription commitment.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
                    Buy Credits via Voice/SMS <span style={{ color: '#12c099' }}>(beta)</span>
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                    On the move? Call or text our 24-hour line at <Link href="tel:+18773556524" sx={{ color: '#12c099' }}>+1 (877) 355-6524</Link> to purchase credits.
                </Typography>

                <Box
                    display="flex"
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    marginTop="1rem"
                    gap="1rem"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ContactPhoneIcon />}
                        onClick={handleDownloadVCF}
                        sx={{
                            backgroundColor: '#12c099',
                            '&:hover': { backgroundColor: '#0f9f85' },
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Add to Contacts
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SmsIcon />}
                        onClick={handleSendSMS}
                        sx={{
                            backgroundColor: '#12c099',
                            '&:hover': { backgroundColor: '#0f9f85' },
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Text Now
                    </Button>
                </Box>

                {/* App Store Download Buttons */}
                <Grid
                    container
                    spacing={1} // Reduced spacing between items
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ marginTop: '2rem' }}
                >
                    <Grid item xs={6} sm={2}> {/* Reduced Grid width on larger screens */}
                        <Link href="https://apps.apple.com/us/app/mars-reel-academy/id6680150371" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                                alt="Download on the App Store"
                                style={{ width: '100%', maxWidth: '150px' }}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={2}> {/* Reduced Grid width on larger screens */}
                        <Link href="https://play.google.com/store/apps/details?id=com.example.app" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Get it on Google Play"
                                style={{ width: '100%', maxWidth: '150px' }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Collapse>
        </Box>
    );
};

export default UnderstandingCredits;
